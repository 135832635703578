<template>
  <div>
    <img src="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/shuzijingji.png" style="width: 100%;height:400px">
    <div class="article_detail_box">
      <breadcrumb class="breadcrumb" :pathList="pathList"></breadcrumb>
      <!-- 正文部分 -->
      <!-- 文章大图 -->
      <!-- <img :src="article.annex" width="100%" height="300px"> -->
      <!-- 文章标题 -->
      <div class="title">{{article.title}}</div>
      <!-- 时间 -->
      <div class="time">{{article.infoDate}}<span v-if="article.source" style="margin-left: 10px">来源：{{article.source}}</span></div>
      <!-- 内容 -->
      <div ref="desc" class="desc"></div>
      <!-- 转载 -->
      <div class="reprint" v-if="article.reprint">转载自：<a :href="article.reprint" target="_blank">{{article.reprint}}</a></div>
      <!-- 上下篇 -->
      <div class="change" @click="toNext(article.upID)">上一篇：{{article.upID ? "《"+article.upTitle+"》" : '没有了' }}</div>
      <div class="change" @click="toNext(article.downID)">下一篇：{{article.downID ? "《"+article.downTitle+"》" : '没有了' }}</div>
    </div>
  </div>
</template>

<script>
  import {
    get,
    post
  } from '../../request/http'
  let that
  export default {
    data() {
      return {
        pathList: [],
        article: {},
        id: '',
        active: ''
      }
    },
    mounted() {
      that = this
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      this.active = this.$route.query.active
      if (this.$route.query.active == 0) {
        this.pathList = [{
            name: '行业资讯',
            path: '/index/message'
          },
          {
            name: '软件行业',
            path: '/index/message?active=0'
          },
          {
            name: '资讯详情',
            path: '/index/message/detail?active=0'
          },
        ]
      } else if (this.$route.query.active == 1) {
        this.pathList = [{
            name: '行业资讯',
            path: '/index/message'
          },
          {
            name: '医药行业',
            path: '/index/message?active=1'
          },
          {
            name: '资讯详情',
            path: '/index/message/detail?active=1'
          },
        ]
      } else {
        this.pathList = [{
            name: '数字经济',
            path: '/index/digital'
          },
          {
            name: '资讯详情',
            path: '/index/digital/detail?active=2'
          },
        ]
      }
      this.id = this.$route.query.id
      this.getArticleDetail()
    },
    methods: {
      getArticleDetail() {
        get('/information/getInfo?id=' + this.id).then(res => {
          if (res.code == 200) {
            that.article = res.data
            that.$refs.desc.innerHTML = res.data.content
          } else {
            that.$message.warning(res.msg)
          }
        })
      },
      toNext(id) {
        if (id) {
          that.$router.push({
            path: '/index/message/detail?active=' + this.active + '&id=' + id
          })
        } else {
          that.$message({
            type: 'warning',
            message: '没有更多了！'
          })
        }
      }
    },
    watch: {
      $route() {
        this.active = this.$route.query.active
        if (this.$route.query.active == 0) {
          this.pathList = [{
              name: '行业资讯',
              path: '/index/message'
            },
            {
              name: '软件行业',
              path: '/index/message?active=0'
            },
            {
              name: '资讯详情',
              path: '/index/message/detail?active=0'
            },
          ]
        } else if (this.$route.query.active == 1) {
          this.pathList = [{
              name: '行业资讯',
              path: '/index/message'
            },
            {
              name: '医药行业',
              path: '/index/message?active=1'
            },
            {
              name: '资讯详情',
              path: '/index/message/detail?active=1'
            },
          ]
        } else {
          this.pathList = [{
              name: '数字经济',
              path: '/index/digital'
            },
            {
              name: '资讯详情',
              path: '/index/digital/detail?active=2'
            },
          ]
        }
        this.id = this.$route.query.id
        this.getArticleDetail()
      }
    }
  }
</script>

<style lang="less" scoped>
  .article_detail_box {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;

    .breadcrumb {
      height: 45px;
      line-height: 45px;
      box-sizing: border-box;
      margin-bottom: 15px;
      border-bottom: 1px dashed #ccc;
      color: #333333;
    }

    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      color: #333;
    }

    .time {
      margin: 14px 0;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
    }

    .reprint {
      margin-top: 30px;
    }

    .change {
      margin-top: 30px;
      color: #333333;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
    }
  }
</style>
<style>
  .desc {
    line-height: 1.5;
  }

  .desc img {
    display: block;
    margin: 0 auto;
  }
</style>
